/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Buffer } from 'buffer';
import {
  Card, Container, Row, Col, Toggle, Tab, Tabs, Inline, Typography,
} from '@airbus/components-react';
import { useSearchParams } from 'react-router-dom';
import { BuildOutline, FolderOpen, FormatListBulleted } from '@airbus/icons/react';
import React from 'react';
import {
  MOD, PART, CONCURRENT_REQUIREMENTS, DATASOURCE_CONSTANT, SB_STATUS_VIEW,
} from '../../../utils/constants';
import './FilterPanel.scss';
import { useAppSelector, useAppDispatch } from '../../../store/hooksTypes';
import DataSelection from '../../PopupFilter/DataSelection/DataSelection';
import { loadSbBrowseData } from '../../../models/sbDataTableModel/sbDataTableThunk';
import { loadConcurrentData } from '../../../models/concurrentTableModel/concurrentTableThunk';
import { updateConcurrentToggle, updateSbStatusViewToggle } from '../../../models/sbDataTableModel/sbDataTableSlice';
import { setBrowseState } from '../../../models/appConfigModel/appConfigSlice';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import { getFromLocalStore } from '../../../utils/commonMethods/generalMethods';

interface FilterPanelProps{
  showDataSelectionButton?: boolean
  filterProps?: any
  showPartTab?: boolean
}
const defaultFilterPanelProps = {
  showDataSelectionButton: false,
  filterProps: {},
  showPartTab: false,
};
const FilterPanel: React.FC<FilterPanelProps> = (props:FilterPanelProps) => {
  const { showDataSelectionButton, filterProps, showPartTab } = props;
  const isDataSelectionModalOpen = useAppSelector((state) => state.popUpFilter.modalToggle);
  const selctedPopupFilters = useAppSelector((state) => state.popUpFilter.sb_filter_selection_configuration);
  const dataTable = useAppSelector((state) => state.displaySbDataTable);
  const sbStatustoggleChange = useAppSelector((state) => state.displaySbDataTable.sbStatusViewToggle);
  const sbConcurrenttoggleChange = useAppSelector((state) => state.displaySbDataTable.concurrentToggle);
  const browserDispatcher = useAppDispatch();
  const [qryParams] = useSearchParams();
  const selctedBrowserTab = useAppSelector((state) => state.app);
  const filterStates = useAppSelector((state) => state.popUpFilter);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    browserDispatcher(updateConcurrentToggle({ ...dataTable, concurrentToggle: value }));
    if (showDataSelectionButton) {
      const bodyPayload = {
        aircraft_type: selctedPopupFilters?.aircraft_type,
        id_aircraft_registration: selctedPopupFilters?.selected_msn,
        sb_reference: selctedPopupFilters?.selected_sb,
        mod_reference: selctedPopupFilters?.selected_mod,
      };
      const res = Object.fromEntries(Object.entries(bodyPayload).filter(([k, v]) => v != null && v.length > 0));
      let sbDataPayload: any = {
        conditions: { ...res }, operator_code_icao: new Array(selctedPopupFilters?.operator_name), isConcurrentToggle: value, isSbStatusToggle: dataTable.sbStatusViewToggle, offset: 0, limit: Number(getFromLocalStore('apiPaginationSize')),
      };
      if (Object.keys(dataTable.FilterPayload).length > 0) { sbDataPayload = { ...dataTable.FilterPayload, isConcurrentToggle: value, isSbStatusToggle: dataTable.sbStatusViewToggle }; }
      browserDispatcher(loadSbBrowseData(sbDataPayload)());
    } else {
      const qryParamsSet = qryParams.getAll('msn_sb');
      const qryParamsSetDecrypted = JSON.parse(Buffer.from(qryParamsSet[0], 'base64').toString('ascii'));
      qryParamsSetDecrypted.isConcurrentToggle = value;
      browserDispatcher(loadConcurrentData(qryParamsSetDecrypted)());
    }
  };

  const handleSbStatusToggleChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    // whenever toggle is on API is dispatched mod column is removed and also sbref null values also removed
    // whenever toggle is off it will be as actual table will be displayed again
    browserDispatcher(updateSbStatusViewToggle({ ...dataTable, sbStatusViewToggle: value }));
    if (sbStatustoggleChange && showDataSelectionButton) {
      const bodyPayload = {
        aircraft_type: selctedPopupFilters?.aircraft_type,
        id_aircraft_registration: selctedPopupFilters?.selected_msn,
        sb_reference: selctedPopupFilters?.selected_sb,
        mod_reference: selctedPopupFilters?.selected_mod,
      };
      const res = Object.fromEntries(Object.entries(bodyPayload).filter(([k, v]) => v != null && v.length > 0));
      let sbTogglePayload: any = {
        conditions: { ...res }, operator_code_icao: new Array(selctedPopupFilters?.operator_name), isConcurrentToggle: dataTable.concurrentToggle, offset: 0, limit: Number(getFromLocalStore('apiPaginationSize')),
      };
      if (Object.keys(dataTable.FilterPayload).length > 0) {
        sbTogglePayload = { ...dataTable.FilterPayload, isConcurrentToggle: dataTable.concurrentToggle };
        delete sbTogglePayload.isSbStatusToggle;
      }
      browserDispatcher(loadSbBrowseData(sbTogglePayload)());
    } else {
      const bodyPayload = {
        aircraft_type: selctedPopupFilters?.aircraft_type,
        id_aircraft_registration: selctedPopupFilters?.selected_msn,
        sb_reference: selctedPopupFilters?.selected_sb,
        mod_reference: selctedPopupFilters?.selected_mod,
      };
      const requestBody: any = Object.fromEntries(Object.entries(bodyPayload).filter(([k, v]) => v != null && v.length > 0));
      let sbTogglePayload: any = {
        conditions: { ...requestBody }, operator_code_icao: new Array(selctedPopupFilters?.operator_name), isConcurrentToggle: dataTable.concurrentToggle, isSbStatusToggle: value, offset: 0, limit: Number(getFromLocalStore('apiPaginationSize')),
      };
      if (Object.keys(dataTable.FilterPayload).length > 0) { sbTogglePayload = { ...dataTable.FilterPayload, isSbStatusToggle: value, isConcurrentToggle: dataTable.concurrentToggle }; }
      browserDispatcher(loadSbBrowseData(sbTogglePayload)());
    }
  };
  return (
    <Container className="bg-coolgrey-10 p-2 top-filter-container">
      <Card className="filter-panel" data-testid="filter-card">
        <Row>
          <Col xxs={5} className="column-tab">
            <Inline spacing="5-x">
              <Tabs
                aria-label="Tabs purpose label"
                size="medium"
                value={useAppSelector((state) => state.app.selectedTab)}
                data-testid="browser-click"
                onChange={(event, value) => {
                  browserDispatcher(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' })); browserDispatcher(setBrowseState({ ...selctedBrowserTab, selectedTab: value }));
                }}
              >
                <Tab icon={<BuildOutline />} disabled={showPartTab} data-testid="part-textname">
                  {PART}
                </Tab>
                <Tab icon={<FolderOpen />} data-testid="mod-textname">
                  {MOD}
                </Tab>
              </Tabs>
              {!selctedBrowserTab.selectedTab && filterStates.part_filter_selection_configuration?.selected_dataSource && (
                <Inline spacing="1-x" className="datasource-inline">
                  <Typography variant="medium" className="label-datasource">
                    {DATASOURCE_CONSTANT}
                  </Typography>
                  <Typography
                    variant="medium"
                    className="text-datasource"
                  >
                    {filterStates.part_filter_selection_configuration?.selected_dataSource}
                  </Typography>
                </Inline>
              )}
            </Inline>
          </Col>
          <Col xxs={2} className={`${filterProps.isInitialLoad && showDataSelectionButton ? 'toggle-column-with-popup' : 'toggle-column'}`}>
            {(selctedBrowserTab.selectedTab === 1 && showDataSelectionButton && !isDataSelectionModalOpen) && (
              <>
                <span>
                  <Toggle disabled={dataTable.isBrowserDataLoading} size="small" data-testid="sb-toggle-button" onChange={handleSbStatusToggleChange} checked={sbStatustoggleChange} />
                </span>
                <span className="concurrent-text">
                  {SB_STATUS_VIEW}
                </span>
              </>
            )}
          </Col>
          <Col xxs={3} className={`${filterProps.isInitialLoad && showDataSelectionButton ? 'toggle-column-with-popup' : 'toggle-column'}`}>
            {(selctedBrowserTab.selectedTab === 1 && !isDataSelectionModalOpen) && (
            <>
              <span>
                <Toggle disabled={dataTable.isBrowserDataLoading} size="small" data-testid="toggle-button" onChange={handleToggleChange} checked={sbConcurrenttoggleChange} />
              </span>
              <span>
                <FormatListBulleted className="concurrent-image" />
              </span>
              <span className="concurrent-text">
                {CONCURRENT_REQUIREMENTS}
              </span>
            </>
            )}
          </Col>
          <Col xxs={2} className="data-selection-column">
            {showDataSelectionButton && (
              <DataSelection selectionProps={filterProps} />
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
FilterPanel.defaultProps = defaultFilterPanelProps;
export default FilterPanel;
