/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';
import { GET_FILE_STATUS_API_PATH, LOAD_GET_API_METHOD } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { FILE_STATUS_SLICE, GET_FILE_STATUS } from './constants';

export const getUploadHistoryFileStatus: any = (uploadHistoryPayload:FileStatusPayloadType) => {
  const customAction = {
    sliceName: FILE_STATUS_SLICE,
    actionType: GET_FILE_STATUS,
  };
  const apiRequestObj = {
    path: GET_FILE_STATUS_API_PATH,
    method: LOAD_GET_API_METHOD,
    queryStringParameters: {
      operator_code_icao: uploadHistoryPayload?.icaoCode,
      limit: Number(getFromLocalStore('fliApiPaginationSize')),
      offset: uploadHistoryPayload?.offset || 0,
      page_number: uploadHistoryPayload?.pageNumber,
    },
  };
    // Add API request obj and create custom thunk
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
