import { createSlice } from '@reduxjs/toolkit';
import { FILE_STATUS_INITIAL_STATE, FILE_STATUS_SLICE } from './constants';
import { getUploadHistoryFileStatus } from './fileStatusThunk';
import { fileStatusFullfilled, fileStatusPending, fileStatusRejected } from './fileStatusExtraReducers';
import { updateSelectedIcaoCode, uploadHistoryPaginationReducer } from './fileStatusReducers';

export const fileStatusSlice = createSlice({
  name: FILE_STATUS_SLICE,
  initialState: FILE_STATUS_INITIAL_STATE,
  reducers: {
    setSelectedIcao: updateSelectedIcaoCode,
    setHistoryPagination: uploadHistoryPaginationReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUploadHistoryFileStatus().pending, fileStatusPending)
      .addCase(getUploadHistoryFileStatus().rejected, fileStatusRejected)
      .addCase(getUploadHistoryFileStatus().fulfilled, fileStatusFullfilled);
  },
});
export const { setSelectedIcao, setHistoryPagination } = fileStatusSlice.actions;
export default fileStatusSlice;
