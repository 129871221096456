/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button, Checkbox, Col, Inline, Popover, Row, SearchInput, Stack, Typography,
} from '@airbus/components-react';
import { UnfoldLess, UnfoldMore, Reset } from '@airbus/icons/react';
import {
  APPLY, CANCEL, CLEAR, COMBOBOX_LABEL_SELECT_ALL, COMBOBOX_LABEL_SELECT_FILTERED, COMBOBOX_PLACEHOLDER, COMBOBOX_PLACEHOLDER_ALL,
} from '../../../utils/constants';
import './HybridComboBox.scss';
import UseHybridComboOutsideClick from './UseHybridComboOutsideClick';

interface HybridComboBoxProps {
  options?: any; // options for the popover
  state: any; // state
  dispatcher: any; // dispatch method
  placeHolder?: string; // placeholder text
  selectedOptions: any; // selectedOptions in combo
  isSearchDisabled: boolean; // to enable to type in
  disabled: boolean; // to disable the button to open popover
  id: any; // unique id for each combo
  searchPattern?: string; // search pattern eg:startsWith by default its contains
  hideSelectAll?: boolean; // to hide the select all checkbox
  maxSelections?: number; // if maxSelections are passed from parent comp then hideSelectAll has to pass as true
  showApply?: boolean; // to show the apply button
  handleApply: any; // apply button handle function
  hybridComboClassName?: string; // css changes
  placement?: any; // popover placement
  isLazyloading?: boolean;
  perPage?: number;
  mode?: string; // Hybrid combobox used in Column level filter or Data selection
  handleFocus?: any;
  applyFocus?: boolean;
}
const defaultProps = {
  placeHolder: COMBOBOX_PLACEHOLDER,
  hideSelectAll: false,
  maxSelections: undefined,
  searchPattern: 'contains',
  showApply: true,
  hybridComboClassName: '',
  placement: 'bottom',
  options: [],
  isLazyloading: false,
  perPage: 500,
  mode: 'dataSelection',
  applyFocus: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleFocus: () => { },

};

export const HybridComboBox: React.FC<HybridComboBoxProps> = function HybridComboBox(props: HybridComboBoxProps) {
  const {
    options,
    state,
    dispatcher,
    selectedOptions,
    placeHolder,
    isSearchDisabled,
    hideSelectAll,
    maxSelections,
    disabled,
    id,
    searchPattern,
    showApply,
    handleApply,
    hybridComboClassName,
    placement,
    isLazyloading,
    perPage,
    mode,
    handleFocus,
    applyFocus,
  } = props;

  // -----------------------------------------------CONSTANTS---------------------------------------- //
  const reference = React.useRef(id);

  // ----------------------------------------------STATE VARIABLES---------------------------------- //
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [currentStateData, setCurrentStateData] = useState<Array<any>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [selectType, setSelectType] = useState(COMBOBOX_LABEL_SELECT_ALL);
  const [totalFormattedOptions, setTotalFormattedOptions] = useState<Array<any>>([]);
  const [componentLabel, setComponentLabel] = useState<any>(placeHolder);
  const [searchSubStr, setSearchSubStr] = useState<string>('');
  const [lastPage, setLastPage] = useState<number>(0);
  const [totalOptionsCount, setTotalOptionsCount] = useState<number>(0);
  const [onchangeCheckBox, setOnchangeCheckBox] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  // ------------------------------FUNCTION DECLARATIONS AND DEFINATIONS---------------------------- //

  UseHybridComboOutsideClick(reference, () => {
    setOpen(false);
  });

  const paginate = (items: Array<any>, pageNum: number) => {
    if (items.length <= (perPage!)) {
      setTotalOptionsCount(items.length);
      return items;
    }
    const start = (perPage!) * pageNum;
    const slicePage = items.slice(start, start + (perPage!));
    return slicePage;
  };

  const applyHandler = () => {
    const selectItemsValues = totalFormattedOptions.filter((checkbox: any) => checkbox.checked)
      .map((item: any) => {
        if (Object.prototype.hasOwnProperty.call(item, 'column')) { return item; } return item.value;
      });
    handleApply(selectItemsValues, state, dispatcher);
  };

  const subStringContainsSearch = (totalOptions: any) => {
    const searchRegex = new RegExp(
      searchSubStr.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      'i',
    );
    const filteredItemsText = totalOptions.filter((checkbox: any) => searchRegex.test(checkbox.label));
    setSelectAll(
      filteredItemsText.length
        ? filteredItemsText.filter((item: any) => item.checked).length
        === filteredItemsText.length
        : false,
    );
    setTotalOptionsCount(filteredItemsText.length);
    setLastPage(
      Math.floor((filteredItemsText.length) / (perPage!)),
    );
    setCurrentStateData(paginate(filteredItemsText, page));
  };

  const checkDisabled = (item: any): boolean => {
    if (selectedOptions?.includes(item)) {
      return false;
    }
    return selectedOptions?.length === maxSelections;
  };

  const setInitialState = () => {
    const isChecked = (selectedOptions?.length && options?.length && selectedOptions?.length === options?.length);
    const formattedOptions = options?.map((item: any) => {
      if (Object.prototype.hasOwnProperty.call(item, 'column')) {
        return {
          label: item.label,
          value: item.value,
          column: item.column,
          checked: selectedOptions.some(
            (selOptions: any) => selOptions.value === item.value,
          ),
        };
      }
      return {
        label: item,
        value: item,
        checked: selectedOptions?.includes(item),
        disabled: checkDisabled(item),
      };
    });
    setLastPage(Math.floor((formattedOptions.length) / (perPage!)));
    setTotalOptionsCount(formattedOptions.length);
    setTotalFormattedOptions(formattedOptions);
    if (searchSubStr.length === 0) {
      setCurrentStateData(paginate(formattedOptions, page));
      setSelectAll(isChecked);
    } else {
      subStringContainsSearch(totalFormattedOptions);
    }
  };

  const onOpenHandler = () => {
    const isChecked = (selectedOptions?.length && options?.length && selectedOptions?.length === options?.length);
    setSelectedCount(selectedOptions.length);
    if (open && !showApply) {
      applyHandler();
    } else {
      setInitialState();
      setSearchSubStr('');
      setSelectAll(isChecked);
    }
    setOpen(!open);
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = currentStateData.map((checkbox: any) => ({
      ...checkbox,
      checked: !selectAll,
    }));
    const selected = updatedCheckboxes.map((checkbox: any) => checkbox.checked === true);
    setSelectedCount(selected.length);
    setCurrentStateData(updatedCheckboxes);
    setSelectAll(!selectAll);
    if (applyFocus) {
      setOnchangeCheckBox(!onchangeCheckBox);
    }
  };

  const maxSelectionHandler = (checkBoxes: any) => {
    const updatedCheckBoxes = checkBoxes.map((checkbox: any) => {
      if (checkbox.checked) {
        return { ...checkbox, disabled: false };
      }
      return { ...checkbox, disabled: true };
    });
    setCurrentStateData(paginate(updatedCheckBoxes, page));
  };

  const handleCheckboxChange = (labelValue: string) => {
    setOnchangeCheckBox(!onchangeCheckBox);
    const updatedCheckboxes = currentStateData.map((checkbox: any) => (checkbox.value === labelValue
      ? { ...checkbox, checked: !checkbox.checked, disabled: false }
      : { ...checkbox, disabled: false }));
    const selected = updatedCheckboxes.filter(
      (checkbox: any) => checkbox.checked,
    );
    setSelectedCount(selected.length);
    if (maxSelections && maxSelections <= selected.length) {
      maxSelectionHandler(updatedCheckboxes);
    } else {
      setCurrentStateData(paginate(updatedCheckboxes, page));
    }
    setSelectAll(updatedCheckboxes.every((checkbox: any) => checkbox.checked));
  };

  const subStringStartsWithSearch = (totalOptions: any) => {
    const filteredItemsText = totalOptions.filter((checkbox: any) => checkbox.label.toLowerCase().startsWith(searchSubStr.toLowerCase()));
    setSelectAll(
      filteredItemsText.length
        ? filteredItemsText.filter((item: any) => item.checked).length
        === filteredItemsText.length
        : false,
    );
    setTotalOptionsCount(filteredItemsText.length);
    setLastPage(
      Math.floor((filteredItemsText.length) / (perPage!)),
    );
    setCurrentStateData(paginate(filteredItemsText, page));
  };

  const handleSearch = (totalOptions: any) => {
    if (searchPattern === 'startsWith') {
      subStringStartsWithSearch(totalOptions);
    } else {
      subStringContainsSearch(totalOptions);
    }
  };

  const handleFilterReset = () => {
    setSelectedCount(0);
    setSelectAll(false);
    const resetData = totalFormattedOptions.map((item: any) => {
      return { label: item.label, value: item.value, checked: false };
    });
    setTotalFormattedOptions([...resetData]);
    handleSearch([...resetData]);
    if (applyFocus) {
      setOnchangeCheckBox(!onchangeCheckBox);
    }
  };

  const setLabel = () => {
    let displayLabel: any = placeHolder;

    // When all options have been checked
    if (!!selectedOptions?.length && selectedOptions.length === options.length) {
      displayLabel = COMBOBOX_PLACEHOLDER_ALL;
    }

    // When option is selected in column level filter
    if (selectedOptions && selectedOptions.length && mode === 'columnLevel') {
      displayLabel = `${selectedOptions.length} Selected`;
    }

    // When only 1 option is checked
    if (selectedOptions && selectedOptions.length === 1) {
      if (Object.prototype.hasOwnProperty.call(selectedOptions[0], 'column')) {
        displayLabel = selectedOptions[0].label;
      } else {
        displayLabel = selectedOptions;
      }
    }

    // When more than 1 options are checked
    if (selectedOptions && selectedOptions.length > 1 && selectedOptions.length !== options.length) {
      displayLabel = `${selectedOptions.length} Selected`;
    }

    // When there is long text more than 60 characters in options
    if (displayLabel.length > 60) {
      displayLabel = `${displayLabel.slice(0, 60)} ...`;
    }

    setComponentLabel(displayLabel);
  };

  const scrollHandler = () => {
    const bottom = Math.ceil(
      (document.getElementById(id)?.scrollTop || 0)
      + (document.getElementById(id)?.clientHeight || 0),
    ) >= (document.getElementById(id)?.scrollHeight || 0);

    if (bottom && page < lastPage) {
      const pageNum = page + 1;
      setPage(pageNum);
      const x = document.getElementById(id) as HTMLElement;
      x.scrollTop -= 450;
    } else {
      const top = (document.getElementById(id)?.scrollTop || 0) === 0;
      if (top && page > 0) {
        const pageNum = page - 1;
        setPage(pageNum);
        const x = document.getElementById(id) as HTMLElement;
        x.scrollTop += 80;
      }
    }
  };

  const isSelectAllDisabled = () => {
    if (isLazyloading) {
      return totalOptionsCount >= 500;
    }
  };

  const selectAllElement = () => {
    if (maxSelections && maxSelections < totalFormattedOptions.length) {
      return (
        <div className="maxSelection-note">
          {`Note: you can select upto ${maxSelections} aircrafts.`}
        </div>
      );
    }
    if (!hideSelectAll) {
      return (
        <Checkbox
          disabled={isSelectAllDisabled()}
          className="selectall"
          data-testid="multi-select-all"
          label={selectType}
          checked={selectAll}
          onChange={handleSelectAll}
        />
      );
    }
    return <>&nbsp;</>;
  };

  // ------------------------------------------lifecycle hooks----------------------------------- //

  useEffect(() => {
    if (options.length) {
      setInitialState();
    }
  }, [options]);

  useEffect(() => {
    const totalOptions = [...totalFormattedOptions];
    totalOptions.forEach((item: any) => {
      const index = currentStateData.findIndex((value: any) => item.value === value.value);

      if (index >= 0) {
        item.checked = currentStateData[index].checked;
      }
    });
    setTotalFormattedOptions(totalOptions);
  }, [currentStateData]);

  useEffect(() => {
    if (!showApply && !_.isEmpty(totalFormattedOptions) && !applyFocus) {
      applyHandler();
    }
    if (totalFormattedOptions.length) setIsLoader(false);
  }, [totalFormattedOptions]);

  useEffect(() => {
    if (!_.isEmpty(totalFormattedOptions) && applyFocus) {
      applyHandler();
    }
  }, [onchangeCheckBox]);

  useEffect(() => {
    setLabel();
  }, [selectedOptions, selectAll]);

  useEffect(() => {
    if (isLazyloading) {
      document.getElementById(id)?.addEventListener('scroll', scrollHandler);
    }
    return () => {
      document.getElementById(id)?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler, isLazyloading]);

  useEffect(() => {
    const type = searchSubStr.length ? COMBOBOX_LABEL_SELECT_FILTERED : COMBOBOX_LABEL_SELECT_ALL;
    setSelectType(type);
    if (totalFormattedOptions.length) {
      handleSearch(totalFormattedOptions);
    }
  }, [page, searchSubStr]);

  return (
    <>
      <div className="hybrid-combo-button">
        <Button
          ref={reference}
          data-testid="popover-filterbutton"
          disabled={disabled}
          onClick={onOpenHandler}
          onFocus={handleFocus}
          className={mode === 'columnLevel' ? 'filter-menu-button-column-level' : 'filter-menu-button'}
        >
          {componentLabel}
          <div className="arrow">
            {open === false ? <UnfoldMore /> : <UnfoldLess />}
          </div>
        </Button>
      </div>
      <Popover
        open={open}
        placement={placement}
        fallbackPlacements={['bottom-end']}
        referenceNode={reference.current}
        className={hybridComboClassName}
      >
        <div className="typaahead">
          <SearchInput
            disabled={isSearchDisabled || isLoader}
            data-testid="typeahead"
            placeholder="Search"
            onChange={(e, value) => {
              setSearchSubStr(value);
            }}
          />
        </div>
        <div className="menu-options" id={id} data-testid="menu-list-id">
          <Row className="select-all">
            <Col>
              {selectAllElement()}
            </Col>
            <Col className="clear-all">
              <Inline className="checker-multiselect-filter-reset">
                <Typography
                  variant="small"
                  data-testid="checker-reset-filter"
                  className="checker-multiselect-filter-resetlabel"
                  onClick={handleFilterReset}
                >
                  {CLEAR}
                </Typography>
                <div className="checker-multiselect-filter-reset-icon">
                  <Reset
                    data-testid="checker-reset-filter-button"
                    onClick={handleFilterReset}
                  />
                </div>
              </Inline>
            </Col>
          </Row>
          {currentStateData.map((filteredItem: any) => (
            <Stack
              data-testid="checkboxes-stack"
              key={filteredItem.label}
              spacing="1-x"
              className="first-menu"
            >
              <Checkbox
                className="multiselect-checkbox"
                disabled={!!filteredItem?.disabled}
                label={filteredItem.label}
                checked={filteredItem.checked}
                onChange={() => {
                  handleCheckboxChange(filteredItem.value);
                }}
              />
            </Stack>
          ))}
        </div>
        <div className="checkbox-action-buttons">
          {!!maxSelections && (
          <div className="maxSelection-selected-label">
            {`Selected: ${selectedCount}`}
          </div>
          )}
          {showApply && (
            <Button
              className="multiselect-cancel-button"
              size="small"
              onClick={onOpenHandler}
            >
              {CANCEL}
            </Button>
          )}
          {showApply && (
            <Button
              className="multiselect-apply-button"
              size="small"
              variant="primary"
              disabled={
                currentStateData.filter((checkbox: any) => checkbox.checked)
                  .length <= 0
              }
              data-testid="multiselect-apply"
              onClick={() => {
                applyHandler();
                setOpen(false);
              }}
            >
              {APPLY}
            </Button>
          )}
        </div>
      </Popover>
    </>
  );
};

HybridComboBox.defaultProps = defaultProps;
