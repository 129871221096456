import { MATCH, TO_BE_ANALYSED, WRONG } from '../../../utils/constants';

const chipComponentArray = [
  { component: 'Chip', args: { label: 'W', text: WRONG } },
  { component: 'Chip', args: { label: 'M', text: MATCH } },
  { component: 'Chip', args: { label: 'A', text: TO_BE_ANALYSED } },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const helpModalConstants: any = {
  sbFilterHelpIcon: {
    title: 'Data Filter Help',
    mandotory: 'Indicates mandatory fields',
    ismandatory: true,
    withChipLabel: false,
    body: ['User can provide max 5 MSN in case no SB/MOD Reference provided'],
  },
  partFilterHelpIcon: {
    title: 'Data Filter Help',
    mandotory: 'Indicates mandatory fields',
    ismandatory: true,
    withChipLabel: false,
    body: ['User can provide max 5 MSN in case no FIN and PNR Reference provided'],
  },
  changeViewHelpIcon: {
    title: 'Change View Help',
    mandotory: '',
    ismandatory: false,
    withChipLabel: false,
    body: ['User can select or deselect the columns based on the requirement to update the tabular view.', '<input type="checkbox" class="helpCheckbox" checked="checked" disabled="disabled" /> Indicates the column is chosen as a part of filter criteria and hence cannot be dropped.', 'Select minimum of one or more columns to enable the Change view functionality'],
  },
  checkerFleetHelp: {
    title: 'Fleet Help',
    mandotory: '',
    ismandatory: false,
    withChipLabel: true,
    body: chipComponentArray,
  },
  acmFilterHelpIcon: {
    title: 'ACM Filter Help',
    mandotory: 'Indicates mandatory fields',
    ismandatory: true,
    withChipLabel: false,
    body: ['Once an Aircraft Type [AC type] is selected, all the MSN for the Aircraft type would be selected by default.'],
  },
  acmFilterNswcHelpIcon: {
    title: 'ACM Filter NSWC Help',
    mandotory: 'Indicates mandatory fields',
    ismandatory: true,
    withChipLabel: false,
    body: ['Once the Aircraft Type [AC type] is selected, a maximum of 5 MSN can be chosen from the dropdown.'],
  },
  checkerDetailsHelpIcon: {
    title: 'Checker Details Selection',
    mandotory: 'Indicates mandatory fields',
    ismandatory: true,
    withChipLabel: false,
    body: ['Select one or multiple FIN numbers to find the FIN status for set of selected MSN/s', 'Select one or multiple ATA chapters to find the related FIN status for set of selected MSN/s.'],
  },
  checkerReasonDetailsIcon: {
    title: 'Checker Reason Details Help',
    mandotory: '',
    ismandatory: false,
    withChipLabel: true,
    body: chipComponentArray,
  },
};
