/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col, ComboBox, Spinner, Typography,
} from '@airbus/components-react';
import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  AIRCRAFT_TYPE,
  OPERATOR,
  COMBOBOX_PLACEHOLDER,
  MSN,
  AIRCRAFT_SUB_TYPE,
  MSNS_PER_PAGE,
} from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { HybridComboBox } from '../Shared/HybridComboBox/HybridComboBox';
import {
  setCheckerDetailOperatorChanged, setFleetDetailFilter,
  updateCheckerFilterSelection,
  updateCheckerFiltersOptions,
} from '../../models/checkerDetailsModel/checkerDetailsSlice';
import {
  loadCheckerAcSubTypeOptions,
  loadCheckerAcTypeOptions,
  loadCheckerMsnOptions,
  loadMSNApi,
  fetchFleetViewDataForOperator,
} from '../../utils/commonUtil';
import { rowsVisible } from '../../utils/commonMethods/modalPopupMethods';
import { setValidCheckerValue, setValidCheckerOptions } from '../../utils/commonMethods/generalMethods';
import { handleMsnChange } from './Utils/CheckerDetailsUtils';

const CheckerFilterPanel: React.FC = function CheckerFilterPanel() {
  const {
    checkerDetailFiltersSelected, fleetDetailFilterOptions, checkerDetailsFilterOptions, ifOperatorChanged, ifAircraftTypeChanged, ifAircraftSubTypeChanged,
  } = useAppSelector((state) => state.displayCheckerDetailsTable);
  const [isChanged, setIsChanged] = React.useState(false);
  const [viewCheckerData, setViewCheckerData] = React.useState<Array<any>>([]);
  const checkerDetailsState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const checkerFleetState = useAppSelector((state) => state.checkerFleet);
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const checkersFilterDispatch = useAppDispatch();

  useEffect(() => {
    const selectedFleetData = checkerState.data[checkerDetailsState.fleet_detail_filter_selected.operator_name] || [];
    setViewCheckerData(selectedFleetData);
    loadCheckerAcTypeOptions(checkerDetailsState.fleet_detail_filter_selected.operator_name, '', checkersFilterDispatch, selectedFleetData, 'CheckerDetailsOperatorChanged');
    loadCheckerAcSubTypeOptions(
      checkerDetailsState.fleet_detail_filter_selected.operator_name,
      '',
      checkerDetailsState.checkerDetailFiltersSelected.aircraft_type,
      checkersFilterDispatch,
      selectedFleetData,
      'CheckerDetailsOperatorChanged',
    );
    loadCheckerMsnOptions(
      checkerDetailsState.fleet_detail_filter_selected.operator_name,
      '',
      checkerDetailsState.checkerDetailFiltersSelected.aircraft_type,
      checkerDetailsState.checkerDetailFiltersSelected.aircraft_subtype,
      checkersFilterDispatch,
      selectedFleetData,
      'CheckerDetailsOperatorChanged',
    );
  }, [checkerState.fleet_view_filters]);
  const handleOperatorSelection = (selectedCheckerOperator: any) => {
    const filterValue = {
      ...checkerDetailsState.fleet_detail_filter_selected,
      aircraft_type: '',
      aircraft_subtype: '',
      selected_msn: [],
      operator_name: selectedCheckerOperator,
    };
    checkersFilterDispatch(setCheckerDetailOperatorChanged({ ...checkerDetailsState, ifOperatorChanged: true }));
    checkersFilterDispatch(setFleetDetailFilter({ ...checkerDetailsState, fleet_detail_filter_selected: filterValue }));
    if (selectedCheckerOperator !== '') {
      checkersFilterDispatch(
        updateCheckerFilterSelection({
          operator_name: selectedCheckerOperator,
          selected_msn: [],
          aircraft_type: [],
          aircraft_family: '',
          aircraft_subtype: [],
          selected_ata: [],
          selected_fin: [],
        }),
      );
      fetchFleetViewDataForOperator(checkerState, checkersFilterDispatch, [selectedCheckerOperator]);
    }
  };

  const handleAcTypeSelection = (selectedAcTypes: any) => {
    if (selectedAcTypes !== '') {
      const updateFilters = checkerFleetState.data[
        checkerDetailsState.fleet_detail_filter_selected.operator_name
      ]?.reduce((result: any, item: any) => {
        if (item?.ac_type === selectedAcTypes && item.ac_type) {
          result.push(item);
        }
        return result;
      }, []);
      const filterValue = {
        ...checkerDetailsState.fleet_detail_filter_selected,
        aircraft_type: new Array(selectedAcTypes),
        aircraft_family: updateFilters[0]?.ac_family,
        aircraft_subtype: '',
        selected_msn: [],
      };
      checkersFilterDispatch(
        updateCheckerFilterSelection({
          operator_name: checkerDetailsState.checkerDetailFiltersSelected.operator_name,
          selected_msn: [],
          aircraft_type: [],
          aircraft_family: updateFilters[0]?.ac_family,
          aircraft_subtype: [],
          selected_ata: [],
          selected_fin: [],
        }),
      );
      checkersFilterDispatch(setFleetDetailFilter({ ...checkerDetailsState, fleet_detail_filter_selected: filterValue }));
      checkersFilterDispatch(
        updateCheckerFiltersOptions({
          ac_family: checkerDetailsState.fleetDetailFilterOptions.ac_family,
          operator_code_icao: checkerFleetState.fleet_view_filters?.operators_list,
          ac_type: checkerFleetState.fleet_view_filters?.ac_type_list,
          ac_sub_type: updateFilters.map((item: any) => item.ac_subtype),
          id_aircraft_registration: updateFilters.map((item: any) => item.id_aircraft_registration),
        }),
      );
      if (!_.isEmpty(selectedAcTypes)) {
        loadCheckerAcSubTypeOptions(
          checkerDetailsState.fleet_detail_filter_selected.operator_name,
          '',
          new Array(selectedAcTypes),
          checkersFilterDispatch,
          viewCheckerData,
          'CheckerDetailsOperatorChanged',
        );
        loadCheckerMsnOptions(
          checkerDetailsState.fleet_detail_filter_selected.operator_name,
          '',
          new Array(selectedAcTypes),
          checkerDetailsState.fleet_detail_filter_selected.aircraft_subtype,
          checkersFilterDispatch,
          viewCheckerData,
          'CheckerDetailsOperatorChanged',
        );
        checkersFilterDispatch(setCheckerDetailOperatorChanged({ ...checkerDetailsState, ifAircraftTypeChanged: true }));
      }
    }
  };

  const handleDetailsAcSubTypeApply = (selectedAcSubTypes: any) => {
    const filterValue = {
      ...checkerDetailsState.fleet_detail_filter_selected,
      selected_msn: [],
      aircraft_subtype: selectedAcSubTypes,
    };
    if (selectedAcSubTypes.length === 0) {
      setIsChanged(true);
    }
    checkersFilterDispatch(setCheckerDetailOperatorChanged({ ...checkerDetailsState, ifAircraftSubTypeChanged: true }));
    checkersFilterDispatch(setFleetDetailFilter({ ...checkerDetailsState, fleet_detail_filter_selected: filterValue }));
    loadMSNApi(ifOperatorChanged as boolean, ifAircraftTypeChanged as boolean, checkerDetailsState, checkerDetailFiltersSelected, selectedAcSubTypes, checkersFilterDispatch, viewCheckerData);
  };
  const handleDetailsMsnApply = handleMsnChange(checkersFilterDispatch, checkerDetailsState, checkerDetailsFilterOptions, checkerDetailFiltersSelected, ifOperatorChanged, ifAircraftTypeChanged, ifAircraftSubTypeChanged);
  const isFieldDisable = checkerDetailsState.fleet_detail_filter_selected?.operator_name?.length > 0 ? !checkerDetailsState.fleet_detail_filter_selected?.operator_name?.length : !fleetDetailFilterOptions?.operator_code_icao?.length;
  return (
    <>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {OPERATOR}
        </Typography>
        <ComboBox
          aria-label="Select DetailsOperator"
          placeholder={COMBOBOX_PLACEHOLDER}
          value={checkerDetailsState.fleet_detail_filter_selected?.operator_name?.length > 0 && ifOperatorChanged ? checkerDetailsState.fleet_detail_filter_selected?.operator_name : checkerDetailFiltersSelected?.operator_name}
          data-testid="details-operator-selector"
          onChange={(value: any) => handleOperatorSelection(value)}
          options={fleetDetailFilterOptions?.operator_code_icao}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          hideClear
        />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {AIRCRAFT_TYPE}
        </Typography>
        <Col xxs={1} className="spinner-left">
          {checkerState.isDataLoading && <Spinner />}
        </Col>
        <ComboBox
          aria-label="Select DetailsAcType"
          placeholder={COMBOBOX_PLACEHOLDER}
          value={setValidCheckerValue(
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailsState.fleet_detail_filter_selected?.aircraft_type?.toString(),
            checkerDetailFiltersSelected?.aircraft_type?.toString(),
            ifOperatorChanged,
          )}
          data-testid="details-acType-selector"
          disabled={isFieldDisable}
          onChange={(value: any) => handleAcTypeSelection(value)}
          options={setValidCheckerOptions(
            checkerDetailsFilterOptions.ac_type,
            fleetDetailFilterOptions?.ac_type,
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailFiltersSelected?.operator_name,
            ifOperatorChanged,
            ifAircraftTypeChanged,
          )}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          hideClear
        />
      </Col>
      <Col xxs={2} className="checker-filters" id="acSubTypeCheckerDetail">
        <Typography variant="small" className="checker-filter-label">
          {AIRCRAFT_SUB_TYPE}
        </Typography>
        <ComboBox
          aria-label="Select DetailsAcSubType"
          placeholder={checkerDetailsState.fleet_detail_filter_selected?.aircraft_subtype.length ? '' : COMBOBOX_PLACEHOLDER}
          limitChips={1}
          value={setValidCheckerValue(
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailsState.fleet_detail_filter_selected?.aircraft_subtype,
            checkerDetailFiltersSelected?.aircraft_subtype,
            ifOperatorChanged,
            isChanged,
            ifAircraftTypeChanged,
          )}
          data-testid="details-acSubType-selector"
          disabled={isFieldDisable}
          onChange={(value: any) => handleDetailsAcSubTypeApply(value)}
          options={setValidCheckerOptions(
            checkerDetailsFilterOptions.ac_sub_type,
            fleetDetailFilterOptions.ac_sub_type,
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailFiltersSelected?.operator_name,
            ifOperatorChanged,
            ifAircraftTypeChanged,
          )}
          allowVirtualization
          virtualizationOptions={{
            overscanCount: 10,
            rowsVisible: rowsVisible(setValidCheckerOptions(
              checkerDetailsFilterOptions.ac_sub_type,
              fleetDetailFilterOptions.ac_sub_type,
              checkerDetailsState.fleet_detail_filter_selected?.operator_name,
              checkerDetailFiltersSelected?.operator_name,
              ifOperatorChanged,
              ifAircraftTypeChanged,
            ), 4),
            rowHeight: 45,
          }}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          multiple
          hideClear
        />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {MSN}
        </Typography>
        <HybridComboBox
          state={checkerDetailsState}
          dispatcher={checkersFilterDispatch}
          isSearchDisabled={false}
          id="detailsMsn"
          disabled={isFieldDisable}
          perPage={MSNS_PER_PAGE}
          options={setValidCheckerOptions(
            checkerDetailsFilterOptions?.id_aircraft_registration,
            fleetDetailFilterOptions?.id_aircraft_registration,
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailFiltersSelected?.operator_name,
            ifOperatorChanged,
            ifAircraftTypeChanged,
            ifAircraftSubTypeChanged,
          )}
          selectedOptions={setValidCheckerValue(
            checkerDetailsState.fleet_detail_filter_selected?.operator_name,
            checkerDetailsState.fleet_detail_filter_selected?.selected_msn,
            checkerDetailFiltersSelected?.selected_msn,
            ifOperatorChanged,
            ifAircraftTypeChanged,
            isChanged,
          )}
          handleApply={handleDetailsMsnApply}
        />
      </Col>
    </>
  );
};

export default CheckerFilterPanel;
