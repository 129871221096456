/* eslint-disable @typescript-eslint/no-explicit-any */
import { getPaginatedChunkData } from '../../utils/commonUtil';

export const updateSelectedIcaoCode = (state: FileStausStateType, action: FliReducerActionType) => {
  return ({
    ...state,
    selectedIcao: action.payload.icaoCode,
  });
};

export const uploadHistoryPaginationReducer = (
  state: FileStausStateType,
  action: any,
) => {
  const calculatedPageNum = action.payload.pageNumber;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    state.data,
    action.payload.rowPerPage,
    prevPage,
    state.paginationData.start,
    state.total_records,
  );
  return ({
    ...state,
    paginationData: {
      ...state.paginationData,
      page: action.payload.pageNumber,
      rowPerPage: action.payload.rowPerPage,
      selectedChunkData: chunkData,
    },
  });
};
