/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card, Container, Row, Col, Tab, Tabs, Inline,
} from '@airbus/components-react';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooksTypes';
import { setCheckerState } from '../../../../../models/appConfigModel/appConfigSlice';
import {
  DETAILS, FLEET, CHECKER, CHECKER_EXPORT_TYPE,
  REPORT_STATUS_FILTERS,
} from '../../../../../utils/constants';
import FleetFilter from '../../../../CheckerFleetFilter/FleetFilter';
import CheckerDetailsFilterPanel from '../../../../CheckerDetailsFilterPanel/CheckerDetailsFilterPanel';
import DataSelection from '../../../../PopupFilter/DataSelection/DataSelection';
import { setHelpModelToggle } from '../../../../../models/helpModel/helpModelSlice';
import { setModalToggle } from '../../../../../models/popupFilterModel/popupFilterSlice';
import {
  fetchFleetViewDataForOperator, getSelectedFleetCardData, handleCheckerDetailsDataSelectionValidate, populateDropdownValues, registerPostHogProperties, columnFilterStateUpdater, loadCheckerAcFamilyOptions, loadCheckerDetailsTable,
  buildCheckerDetailsConditions,
  getReasonStatusMapping,
} from '../../../../../utils/commonUtil';
import CheckerDetailsTable from '../../../../CheckerDetailsTable/CheckerDetailsTable';
import {
  clearFleetDetailSelectedFin,
  setCheckerDetailsInitialLoad, toggleReasonDetail, updateCheckerDetailsColumnFilterPayload, updateCheckerDetailsColumnFilterState, updateCheckerFilterSelection, updateSelectAllBottomDrawerStatus, updateCheckerFiltersOptions, setCheckerDetailOperatorChanged, setFleetDetailFilter,
} from '../../../../../models/checkerDetailsModel/checkerDetailsSlice';
import { getFleetViewData, getFleetViewFilteredData, setCheckerInitialLoad } from '../../../../../models/checkerFleetModel/checkerFleetSlice';
import { checkerReasonStatusMapping, getFleetViewDataFromApi } from '../../../../CheckerFleetFilter/Utils/FleetFilterUtils';
import {
  columns, fleetColumns, AcrColumns, MisColumns,
} from '../../../../CheckerDetailsTable/CheckerDetailsTableStructure';
import { getFromLocalStore, removeFromLocalStore } from '../../../../../utils/commonMethods/generalMethods';

const CheckerTabs: React.FC = function CheckerTabs() {
  const navigate = useNavigate();
  const [urlParams, setUrlParams] = useState({
    conditions: {
      fin_status: [], status_part_check: [], acr_source: [], mis_source: [], acr_source_status: [], mis_source_status: [],
    },
    filterState: {},
  });
  const [params] = useSearchParams();
  const paramString = params.getAll('params');
  const reportStatusFiltersStr: string = getFromLocalStore(REPORT_STATUS_FILTERS) || '{}';
  const reportStatusFiltersObj: any = JSON.parse(reportStatusFiltersStr);
  const showFilteredTable: boolean = JSON.parse(getFromLocalStore('showFilteredTable') || 'false');

  const checkerDispatcher = useAppDispatch();
  const checkerTabState = useAppSelector((state) => state.app);
  const checkerDetailState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const checkerFleetState = useAppSelector((state) => state.checkerFleet);
  const popupFilterState = useAppSelector((state) => state.popUpFilter);
  const checkedDetailsTabDisabled = checkerFleetState.isFilterOperatorLoading;
  const selectionProps = {
    sourceTab: CHECKER,
    sourceOfHelpClick: 'checkerDetailsHelpIcon',
    selectedFilterState: checkerDetailState.checkerDetailFiltersSelected,
    isInitialLoad: checkerDetailState.checkerDetailsInitialLoad,
    checkerFleetState,
    tableState: checkerDetailState,
    dispatcher: checkerDispatcher,
    apiDispatcher: updateCheckerFilterSelection,
    additionalMethods: { setCheckerDetailsInitialLoad, toggleReasonDetail },
    handleValidate: handleCheckerDetailsDataSelectionValidate,
    updateColumnFilterState: updateCheckerDetailsColumnFilterState,
    updateColumnFilterPayload: updateCheckerDetailsColumnFilterPayload,
  };

  useEffect(() => {
    if (
      paramString.length // checking if params string exists or not
      && !!reportStatusFiltersObj // checking if report status filters exists in local storage or not
      && Object.keys(reportStatusFiltersObj).length // checking length of report status filters in local storage
      && showFilteredTable
    ) {
      const decodedValue = JSON.parse(
        Buffer.from(paramString[0], 'base64').toString('ascii'),
      ); // Decrypting query parameters
      setUrlParams(decodedValue);
      const { filterState, conditions } = decodedValue;
      const {
        fin_status, status_part_check, acr_source, mis_source, acr_source_status, mis_source_status,
      } = conditions;

      const { checkerDetailFiltersSelected, fleet_detail_filter_selected, fleetDetailFilterOptions } = reportStatusFiltersObj;
      const {
        operator_name, aircraft_type, selected_msn, aircraft_subtype,
      } = reportStatusFiltersObj.checkerDetailFiltersSelected;
      const filterDispatchParam: any = {
        fleet_fin: [],
        fin_status: [],
        id_aircraft_registration: [],
        ata: [],
        fin_description: [],
        source: [],
        status_part_check: [],
        pnr: [],
        source_status: [],
        reason: [],
      };
      const finalFilterState = { ...filterDispatchParam, ...filterState };
      const filterConditions = buildCheckerDetailsConditions({
        operator: operator_name,
        aircraft: aircraft_type,
        aircraft_subtype,
        msn: selected_msn,
        fin: [],
        ata: [],
        fin_status,
        status_part_check,
        acr_source,
        mis_source,
        acr_source_status,
        mis_source_status,
      });
      const filterPaylod = {
        conditions: { ...filterConditions },
        operator_code_icao: [checkerDetailFiltersSelected.operator_name],
        offset: 0,
        limit: Number(getFromLocalStore('apiPaginationSize')),
        columns: [...columns],
      };
      checkerDispatcher(setCheckerState({ selectedCheckerTab: 1 })); // switching checker tab to details page
      checkerDispatcher(setCheckerDetailsInitialLoad({ ...checkerDetailState, checkerDetailsInitialLoad: false })); // bypassing dataselection popup
      checkerDispatcher(updateCheckerFiltersOptions({ ...fleetDetailFilterOptions })); // loading all filter options in details page
      checkerDispatcher(updateCheckerFilterSelection({ ...checkerDetailFiltersSelected })); // select relevant value for filters
      checkerDispatcher(setCheckerDetailOperatorChanged({ ...checkerDetailState, ifOperatorChanged: false, ifAircraftTypeChanged: false }));
      const filterValue = {
        ...fleet_detail_filter_selected,
      };
      checkerDispatcher(setFleetDetailFilter({ ...checkerDetailState, fleet_detail_filter_selected: filterValue })); // setting fleet view filters
      columnFilterStateUpdater(checkerDispatcher, filterDispatchParam, finalFilterState, updateCheckerDetailsColumnFilterState, updateCheckerDetailsColumnFilterPayload, filterPaylod);
      const reasonStatus = getReasonStatusMapping();
      checkerReasonStatusMapping(checkerDispatcher, reasonStatus, checkerDetailFiltersSelected);
      getFleetViewDataFromApi([checkerDetailFiltersSelected.operator_name], checkerDispatcher); // loading fleet view data
    }
  }, []);

  useEffect(() => {
    if (
      paramString.length // checking if url string exists or not
      && !!reportStatusFiltersObj?.checkerDetailFiltersSelected // checking if report status filter exists or not
      && !!Object.keys(reportStatusFiltersObj?.checkerDetailFiltersSelected).length
      && !!Object.keys(checkerFleetState.data).length // checking if fleet state is set or not
      && showFilteredTable
    ) {
      const {
        operator_name, aircraft_type, selected_msn, aircraft_subtype,
      } = reportStatusFiltersObj.checkerDetailFiltersSelected;

      const {
        fin_status, status_part_check, acr_source, mis_source, acr_source_status, mis_source_status,
      } = urlParams.conditions;

      const selectedOperatorData = checkerFleetState.data[operator_name];
      // Once new tab is opened Added default columns for fleet details API
      // and also seperate columns for ACR and MIS to show based on those columns to fetch those data
      let requiredColumns: any;
      if (typeof acr_source !== 'undefined') {
        requiredColumns = [...fleetColumns, ...AcrColumns];
      } else if (typeof mis_source !== 'undefined') {
        requiredColumns = [...fleetColumns, ...MisColumns];
      } else {
        requiredColumns = [...columns];
      }

      loadCheckerAcFamilyOptions(operator_name, checkerDispatcher, selectedOperatorData); // loading AC family options

      loadCheckerDetailsTable({
        operator: operator_name,
        aircraft: aircraft_type,
        aircraft_subtype,
        msn: selected_msn,
        fin: [],
        ata: [],
        fin_status,
        status_part_check,
        acr_source,
        mis_source,
        acr_source_status,
        mis_source_status,
        dispatch: checkerDispatcher,
      }, requiredColumns); // loading checker details table data
    }
  }, [checkerFleetState, urlParams]);

  useEffect(() => {
    if (checkerTabState.selectedCheckerTab === 1) {
      registerPostHogProperties({
        ICAO_CODE: checkerDetailState.checkerDetailFiltersSelected?.operator_name?.toString(),
      });
      checkerDispatcher(setModalToggle({ modalToggle: checkerDetailState.checkerDetailsInitialLoad }));
      checkerDispatcher(setCheckerInitialLoad({ checkerFleetFilterInitialLoad: false }));
    } else {
      registerPostHogProperties({
        ICAO_CODE: checkerFleetState.fleet_view_filter_selected?.selected_operator?.toString(),
      });
      checkerDispatcher(setCheckerInitialLoad({ checkerFleetFilterInitialLoad: true }));
      // close the reason detail popup if it is open
      checkerDispatcher(toggleReasonDetail(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkerTabState.selectedCheckerTab]);
  useEffect(() => {
    const fleetViewFilterSelected = checkerFleetState.fleet_view_filter_selected;
    let cardData = checkerFleetState.data[checkerFleetState.fleet_view_filter_selected?.selected_operator[0]] || [];
    const fleetViewFilterOption = checkerFleetState.fleet_view_filters;
    // get filter values for filter options
    const filterValues = populateDropdownValues(
      cardData,
      checkerFleetState.fleet_view_filter_selected?.selected_operator[0],
      checkerFleetState.fleet_view_filter_selected?.selected_aircraft,
      checkerFleetState.fleet_view_filter_selected?.selected_sub_aircraft,
      fleetViewFilterOption,
    );
    // dispatch to load filter options
    checkerDispatcher(getFleetViewData({ fleet_view_filters: filterValues }));
    // filter by selected options
    cardData = getSelectedFleetCardData(cardData, fleetViewFilterSelected);
    checkerDispatcher(getFleetViewFilteredData({ visibleData: cardData }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkerFleetState.data]);
  return (
    <>
      <Container className="bg-coolgrey-10 p-2 checker-top-tab-container">
        <Card className="checker-panel">
          <Row className="checker-filter-row">
            <Col xxs={2} className="column-tab">
              <Inline spacing="5-x">
                <Tabs
                  aria-label="Tabs purpose label"
                  size="medium"
                  value={useAppSelector((state) => state.app.selectedCheckerTab)}
                  data-testid="checker-click"
                  id="checker-tab-id"
                  onChange={(event, value) => {
                    checkerDispatcher(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' }));
                    checkerDispatcher(setCheckerState({ selectedCheckerTab: value }));
                    checkerDispatcher(updateSelectAllBottomDrawerStatus(false));
                    checkerDispatcher(clearFleetDetailSelectedFin(!checkerDetailState.isClearSelection));
                    if (value === 0) {
                      // keep the previously applied filters.
                      fetchFleetViewDataForOperator(checkerFleetState, checkerDispatcher, checkerFleetState.fleet_view_filter_selected?.selected_operator, 'fleetView');
                      if (paramString.length) {
                        navigate('/checker');
                        removeFromLocalStore(REPORT_STATUS_FILTERS);
                      }
                    }
                  }}
                >
                  <Tab data-testid="fleet-textname">
                    {FLEET}
                  </Tab>
                  <Tab
                    data-testid="details-textname"
                    disabled={checkedDetailsTabDisabled || checkerFleetState.visibleData?.length === 0}
                  >
                    {DETAILS}
                  </Tab>
                </Tabs>
              </Inline>
            </Col>
            {checkerTabState.selectedCheckerTab === 0 && <FleetFilter />}
            {(checkerTabState.selectedCheckerTab === 1 && !popupFilterState.modalToggle) && <CheckerDetailsFilterPanel />}
            <Col className="dataSelection">{checkerTabState.selectedCheckerTab === 1 && <DataSelection selectionProps={selectionProps} />}</Col>
          </Row>
        </Card>
      </Container>
      {(checkerTabState.selectedCheckerTab === 1 && !checkerDetailState.checkerDetailsInitialLoad) && (
        <CheckerDetailsTable data-testid="checker-details-table" exportType={CHECKER_EXPORT_TYPE} paramString={paramString} />
      )}
    </>
  );
};
export default CheckerTabs;
