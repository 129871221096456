/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Tab, Tabs } from '@airbus/components-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './HeaderTab.scss';
import { setHelpModelToggle } from '../../../../models/helpModel/helpModelSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import {
  checkFetchedStatus, generateHeaderTabText, generateToastPayload, setTab,
} from '../../../../utils/commonMethods/generalMethods';
import { toggleReasonDetail } from '../../../../models/checkerDetailsModel/checkerDetailsSlice';
import { getUserPermissions } from '../../../../models/authModel/authEntryThunks';
import { getFileListForDownload } from '../../../../utils/commonUtil';
import { setPolling } from '../../../../models/downloadStatusModel/downloadStatusSlice';
import { getFileStatus, getPresignedUrl } from '../../../../models/downloadStatusModel/getStatusThunk';
import { withLongPolling } from '../../../../utils/apiUtils/longPollingWrapper';
import { LONG_POLLING_DELAY, TOAST_TIMEOUT } from '../../../../utils/constants';
import { addToast, removeToast } from '../../../../models/toastModel/toastSlice';

interface HeaderTabs {
  id: number;
  title: string;
  routeTo: string;
  disabled: boolean;
}

const HeaderTab: React.FC = function HeaderTab() {
  const [selectedTab, setSelectedTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [headerTabText, setHeaderTabText] = useState<Array<HeaderTabs>>([]);
  const [filesReadyForDownload, setFilesReadyForDownload] = useState<
      Array<DownloadStatus>
    >([]);
  const [pollingHashes, setPollingHashes] = useState<Array<HashIdObject>>([]);
  const exportDispatch = useAppDispatch();
  const { downloadStatus } = useAppSelector((state) => state.downloadStatus);
  const authEntry = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);

  useEffect(() => {
    let allowedRoutes = authEntry.moduleList;
    const exportAcm = featureToggleConfig?.exportAcm === 'true';
    if (!exportAcm) {
      allowedRoutes = allowedRoutes.filter((item: string) => item !== '/acm');
    }
    setHeaderTabText(generateHeaderTabText(allowedRoutes));
  }, [authEntry.moduleList, featureToggleConfig.exportAcm]);

  useEffect(() => {
    const currNav: any = headerTabText.filter((navItem) => location.pathname === navItem.routeTo || ((location.pathname === '/checker/details' || location.pathname === '/checker') && navItem.routeTo === '/'))[0];
    // For Concurent tab, selected module will always be the browser
    // for dev and val if checker exists then by default checker will be selected
    // else whatever comes in the middle of the list.
    setTab(location, setSelectedTab, authEntry, currNav);

    // close the reason detail popup if it is open and tab is switched
    dispatch(toggleReasonDetail(false));
  }, [location, selectedTab, toggleReasonDetail, authEntry.moduleList]);
  useEffect(() => {
    const hashIds = downloadStatus
      .filter((allFileStat) => allFileStat.polling_status?.includes('Progress'))
      .map((eachFileStat) => (
        {
          hash_id: eachFileStat.hash_id,
          timestamp: eachFileStat.timestamp,
        }
      ));
    setPollingHashes(hashIds);
    setFilesReadyForDownload(getFileListForDownload(downloadStatus));
  }, [downloadStatus]);
  useEffect(() => {
    // set the polling status to false when starting polling
    setPolling(false);
    if (pollingHashes.length > 0) {
      const pollingOperators = downloadStatus[0]?.operator_code_icao.join(',');
      const fileStatusDispatcher = getFileStatus(pollingHashes, pollingOperators);
      const pollFunction = withLongPolling(
        fileStatusDispatcher,
        LONG_POLLING_DELAY,
        checkFetchedStatus,
      );
      pollFunction('download-status', setPolling, true, exportDispatch);
    }
  }, [pollingHashes.length]);
  useEffect(() => {
    // dispatch the presigned url thunk when polling is completed
    // prepare list of hash ids for which file is prepared and not previously downloaded
    if (filesReadyForDownload.length > 0) {
      filesReadyForDownload.forEach((requiredFileList: any) => {
        setTimeout(() => {
          exportDispatch(
            getPresignedUrl({
              hash_id: requiredFileList?.hash_id,
              operator_code_icao: requiredFileList?.operator_code_icao,
              timestamp: requiredFileList?.timestamp,
              filters: requiredFileList?.filter_payload && JSON.parse(requiredFileList?.filter_payload),
              export_type: requiredFileList?.export_type,
              file_type: requiredFileList?.file_name?.split('.')[1],
            })(),
          ).then(() => {
            const toastPayload = generateToastPayload(
              'global',
              `Configuration data export ${requiredFileList?.file_name} has been downloaded successfully.`,
              200,
            );
            exportDispatch(addToast({ ...toastPayload, isToastShown: true }));
            setTimeout(() => {
              exportDispatch(
                removeToast({ ...toastPayload, isToastShown: false }),
              );
            }, TOAST_TIMEOUT);
          });
        }, 10);
      });
    }
  }, [filesReadyForDownload.length]);
  return (
    <Container until="xs" className="tab-container">
      <Tabs value={selectedTab} className="tab-select-container">
        {
        headerTabText.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            onClick={() => {
              navigate(tab.routeTo, {
                state: location.pathname,
              });
              dispatch(getUserPermissions()());
              dispatch(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' }));
            }}
            size="large"
            aria-label="HeaderNav"
            className="selected-tab"
            disabled={tab.disabled}
          >
            {tab.title}
          </Tab>
        ))
      }
      </Tabs>
    </Container>
  );
};
export default HeaderTab;
