import {
  clearFleetDetailSelectedFin,
  setFleetDetailFilter,
  toggleFilterModel,
  toggleReasonDetail,
  updateCheckerDetailsColumnFilterPayload,
  updateCheckerDetailsColumnFilterState,
} from '../../../models/checkerDetailsModel/checkerDetailsSlice';
import { store } from '../../../store/store';
import {
  columnFilterStateUpdater,
  registerPostHogProperties,
  setOptionsAndSelectionMsn,
} from '../../../utils/commonUtil';

export function handleMsnChange(
  checkersFilterDispatch: typeof store.dispatch,
  checkerDetailsState: CheckerDetailsTableStateType,
  checkerDetailsFilterOptions: fleetFilterOptionsTypes,
  checkerDetailFiltersSelected: checkerFilters,
  ifOperatorChanged: boolean | undefined,
  ifAircraftTypeChanged: boolean | undefined,
  ifAircraftSubTypeChanged: boolean | undefined,
) {
  return (selectedMsn: string[]) => {
    checkersFilterDispatch(toggleReasonDetail(false));
    checkersFilterDispatch(toggleFilterModel(false));
    registerPostHogProperties({
      ICAO_CODE:
        checkerDetailsState.fleet_detail_filter_selected?.operator_name?.toString(),
    });
    const filterValue = {
      ...checkerDetailsState.fleet_detail_filter_selected,
      selected_msn: selectedMsn,
      selected_fin: [],
      selected_ata: [],
    };
    checkersFilterDispatch(
      setFleetDetailFilter({
        ...checkerDetailsState,
        fleet_detail_filter_selected: filterValue,
      }),
    );
    setOptionsAndSelectionMsn({
      checkerDetailsState,
      checkerDetailsFilterOptions,
      checkerDetailFiltersSelected,
      dispatch: checkersFilterDispatch,
      selectedMsn,
      ifOperatorChanged,
      ifAircraftTypeChanged,
      ifAircraftSubTypeChanged,
    });
    const filterDispatchParam: string[] = [];
    columnFilterStateUpdater(
      checkersFilterDispatch,
      filterDispatchParam,
      filterDispatchParam,
      updateCheckerDetailsColumnFilterState,
      updateCheckerDetailsColumnFilterPayload,
      filterDispatchParam,
    );
    // To deselect the checkboxes in checker details table
    checkersFilterDispatch(
      clearFleetDetailSelectedFin(!checkerDetailsState.isClearSelection),
    );
  };
}
