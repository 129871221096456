export const historyTableColumns = [
  {
    accessor: 'ingested_filename',
    id: 'ingested_filename',
    align: 'left',
    Header: 'File Name',
    width: 1,
    disableSortBy: true,
  },
  {
    accessor: 'uploaded_date',
    id: 'uploaded_date',
    align: 'left',
    Header: 'Upload Date',
    width: 60,
    disableSortBy: true,
  },
  {
    accessor: 'status',
    id: 'status',
    align: 'left',
    Header: 'Upload Status',
    width: 80,
    disableSortBy: true,
  },
];
