/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Breadcrumb, BreadcrumbItem, Card, Col, ComboBox, Container, Link,
  Row,
  Typography,
} from '@airbus/components-react';
import React, { useEffect } from 'react';
import './UploadFliHistory.scss';
import { Outlet } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  CHECKER,
  COMBOBOX_PLACEHOLDER, DEFAULT_FLI_PAGE, HOME, NSWC_ROW_PER_PAGE_OPTION, OPERATOR, UPLOAD_HISTORY,
} from '../../utils/constants';
import Table from '../Shared/Table/Table';
import { historyTableColumns } from './UploadFliHistoryTableStructure';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getUploadHistoryFileStatus } from '../../models/nswc/fileStatusThunk';
import AppHeader from '../Shared/AppHeader/AppHeader';
import { LOCALES } from '../../assets/locale';
import { loadCheckerOperators } from '../../models/checkerFleetModel/checkerFleetThunk';
import { getCheckerFilters, handleUploadHistoryPageChange } from '../../utils/commonUtil';
import { setOperatorFilters } from '../../models/checkerFleetModel/checkerFleetSlice';
import { setHistoryPagination, setSelectedIcao } from '../../models/nswc/fileStatusSlice';

const UploadFliHistory: React.FC = function UploadFliHistory() {
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const nswcFileState = useAppSelector((state) => state.nswcFileStatus);
  const dispatcher = useAppDispatch();
  const paginationProps = {
    sourceTab: CHECKER,
    tableState: nswcFileState,
    dispatcher,
    handlePageChange: handleUploadHistoryPageChange,
    updateTableDataReducer: getUploadHistoryFileStatus,
    updatePaginationReducer: setHistoryPagination,
    rowPerPageOptions: NSWC_ROW_PER_PAGE_OPTION,
  };
  useEffect(() => {
    // if no filters found, load the operator dropdowns first
    const checkerFilters = getCheckerFilters();

    if (isEmpty(checkerState.fleet_view_filters?.operators_list)) {
      if (isEmpty(checkerFilters.operatorsList)) {
        const operatorPayload: any = {
          conditions: {},
          columns: ['operator_code_icao'],
          distinct: true,
        };
        dispatcher(loadCheckerOperators(operatorPayload)());
      } else {
        // set the operators list from localstore
        dispatcher(setOperatorFilters({ operatorList: checkerFilters.operatorsList }));
        dispatcher(setSelectedIcao({ icaoCode: checkerFilters.operatorsList[0] }));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // when operator list is loaded, select the first icao as selected icao
  useEffect(() => {
    const icaoList = checkerState.fleet_view_filters?.operators_list || [];
    if (!isEmpty(icaoList)) dispatcher(setSelectedIcao({ icaoCode: icaoList[0] }));
  }, [checkerState.fleet_view_filters?.operators_list]);
  // make api call when selected icao changed
  useEffect(() => {
    if (!isEmpty(nswcFileState.selectedIcao)) {
      const payload = {
        icaoCode: nswcFileState.selectedIcao,
        pageNumber: Number(DEFAULT_FLI_PAGE),
      };
      dispatcher(getUploadHistoryFileStatus(payload)());
    }
  }, [nswcFileState.selectedIcao]);
  // on change of icao from dropdown, change selected icao
  const handleIcaoSelected = (icaoValue: string | string []) => {
    dispatcher(setSelectedIcao({ icaoCode: icaoValue }));
  };
  return (
    <Container className="bg-coolgrey-10 p-2 history-table-container">
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
      <Card className="history-card-container">
        <Breadcrumb className="breadcrumb-container-history">
          <BreadcrumbItem>
            <Link href="/" className="home-page">
              {HOME}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem current>{UPLOAD_HISTORY}</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col xxs={8}>
            <Typography variant="large" className="text-content">{UPLOAD_HISTORY}</Typography>
          </Col>
          <Col xxs={1}>
            <Typography variant="small" className="operator-text">{OPERATOR}</Typography>
          </Col>
          <Col xxs={2} className="combo-operator-col">
            <ComboBox
              aria-label="Select Operator"
              placeholder={COMBOBOX_PLACEHOLDER}
              value={nswcFileState.selectedIcao?.toString()
                || checkerState.fleet_view_filter_selected?.selected_operator?.toString()}
              data-testid="upload-history-operator-selector"
              options={checkerState.fleet_view_filters?.operators_list}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              hideClear
              onChange={(value) => handleIcaoSelected(value)}
            />
          </Col>
        </Row>
      </Card>
      <Container className="history-page-container checker-details-container" id="historyContainer">
        <Table
          data-testid="history-data-table"
          tableData={nswcFileState.paginationData.selectedChunkData}
          headerColumns={historyTableColumns}
          showChangeViewButton={false}
          showExportButton={false}
          showFilterButton={false}
          tableClassName="history-data-table"
          loadingupdate={nswcFileState.isFileStatusLoading}
          showPagination
          paginationProps={paginationProps}
        />
      </Container>
    </Container>
  );
};
export default UploadFliHistory;
